import { ProgramStatus, ReferralProgram } from '@wix/ambassador-loyalty-referral-v1-program/types';

interface Params {
  isSiteTemplate: boolean;
  isViewer: boolean;
  referralProgram: ReferralProgram | undefined;
}

export const isProgramAvailable = ({ isSiteTemplate, isViewer, referralProgram }: Params) => {
  const isProgramActive = referralProgram?.status === ProgramStatus.ACTIVE;
  const hasPremium = !!referralProgram?.isPremium;

  if (isSiteTemplate) {
    return true;
  }
  if (!isViewer) {
    return true;
  }
  if (isProgramActive && hasPremium) {
    return true;
  }
  return false;
};
