import { Coupon } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { CommonState, defaultCommonState } from '../../../viewer';

export interface State extends CommonState {
  explanationsText: string;
  isRewardLoading: boolean;
  isTimeoutError: boolean;
  isNotEligibleError: boolean;
  couponReward?: Coupon;
}

export const defaultState: State = {
  ...defaultCommonState,
  explanationsText: '',
  isRewardLoading: false,
  isTimeoutError: false,
  isNotEligibleError: false,
  couponReward: undefined,
};
